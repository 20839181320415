<template>
  <div id="plans">
    <!-- showcase -->
    <div class="showcase-xxs">
      <h4>Select a plan to continue</h4>
      <div class="jtabs-container">
        <button
          class="jbtn-tab"
          v-bind:class="{ active: tabs[0] }"
          @click="changeTab(0)"
        >
          App
        </button>
        <button
          class="jbtn-tab"
          v-bind:class="{ active: tabs[1] }"
          @click="changeTab(1)"
        >
          Package
        </button>
      </div>
    </div>
    <div class="wrapper">
      <!-- section dashboard -->
      <section
        class="section jpadding section-unfixed"
        style="background: #1193f5"
      >
        <transition
          :duration="{ enter: 1000, leave: 200 }"
          name="custom-classes-transition"
          enter-active-class="animated bounceInLeft"
          leave-active-class="animated fade"
          mode="out-in"
        >
          <div v-if="tabs[0]" key="1" class="section-prices">
            <div
              class="section-item jcard jcard-detail price-platform price-sm"
            >
              <p><i class="fad fa-user"></i></p>
              <h5><b>TALKII BASIC</b></h5>
              <h3>14.99€<small style="font-size: 50%;">/m</small></h3>
              <p>if billed yearly</p>
              <div class="jcard-separator"></div>
              <div class="jcard-items-list">
                <p><i class="fal fa-user"></i></p>
                <p>1 user</p>
                <p><i class="fal fa-mobile-android"></i></p>
                <p>Supervisor App</p>
                <p><i class="fal fa-tablet-android"></i></p>
                <p>Tablet App</p>
              </div>
              <router-link
                class="jbtn jbtn-blue"
                :to="{ name: 'BillingCycles', params: { product: 0 } }"
                @click.native="openCycles(0)"
              >
                Choose
              </router-link>
            </div>

            <div
              class="section-item jcard jcard-detail price-platform price-sm"
            >
              <p><i class="fad fa-user-friends"></i></p>
              <h5><b>TALKII DUO</b></h5>
              <h3>22.49€<small style="font-size: 50%;">/m</small></h3>
              <p>if billed yearly</p>
              <div class="jcard-separator"></div>
              <div class="jcard-items-list">
                <p><i class="fal fa-user-friends"></i></p>
                <p>2 users</p>
                <p><i class="fal fa-mobile-android"></i></p>
                <p>Supervisor App</p>
                <p><i class="fal fa-tablet-android"></i></p>
                <p>Tablet App</p>
              </div>
              <router-link
                class="jbtn jbtn-blue"
                :to="{ name: 'BillingCycles', params: { product: 1 } }"
                @click.native="openCycles(1)"
              >
                Choose
              </router-link>
            </div>

            <div
              class="section-item jcard jcard-detail price-platform price-sm"
            >
              <p><i class="fad fa-users"></i></p>
              <h5><b>TALKII FAMILY</b></h5>
              <h3>44.99€<small style="font-size: 50%;">/m</small></h3>
              <p>if billed yearly</p>
              <div class="jcard-separator"></div>
              <div class="jcard-items-list">
                <p><i class="fal fa-users"></i></p>
                <p>4 users</p>
                <p><i class="fal fa-mobile-android"></i></p>
                <p>Supervisor App</p>
                <p><i class="fal fa-tablet-android"></i></p>
                <p>Tablet App</p>
              </div>
              <router-link
                class="jbtn jbtn-blue"
                :to="{ name: 'BillingCycles', params: { product: 2 } }"
                @click.native="openCycles(2)"
              >
                Choose
              </router-link>
            </div>
          </div>

          <div v-else key="2" class="section-prices section-prices-package">
            <div
              class="section-item jcard jcard-detail price-platform price-split price-sm"
            >
              <div class="price-content">
                <p><i class="fad fa-box-open"></i></p>
                <h5><b>TALKII PACKAGE</b></h5>
                <h3>1199€<small style="font-size: 50%;">/y</small></h3>
                <p>if billed yearly</p>
                <div class="jcard-separator"></div>
                <div class="jcard-items-list">
                  <p><i class="fal fa-users"></i></p>
                  <p>4 users</p>
                  <p><i class="fal fa-puzzle-piece"></i></p>
                  <p>Supervisor App</p>
                  <p><i class="fal fa-puzzle-piece"></i></p>
                  <p>Tablet App</p>
                  <p><i class="fal fa-tablet-rugged"></i></p>
                  <p>Protective Case</p>
                  <p><i class="fal fa-tablet-android"></i></p>
                  <p>Samsung Tablet</p>
                </div>
              </div>

              <div class="price-bottom">
                <router-link
                  class="jbtn jbtn-blue"
                  :to="{ name: 'BillingCycles', params: { product: 3 } }"
                  @click.native="openCycles(3)"
                >
                  Choose
                </router-link>
              </div>
            </div>

            <div
              class="section-item jcard jcard-detail price-platform price-sm"
            >
              <p><i class="fad fa-box-open"></i></p>
              <h5><b>TALKII PACKAGE</b></h5>
              <h3>1299€<small style="font-size: 50%;">/y</small></h3>
              <p>if billed yearly</p>
              <div class="jcard-separator"></div>
              <div class="jcard-items-list">
                <p><i class="fal fa-users"></i></p>
                <p>4 users</p>
                <p><i class="fal fa-puzzle-piece"></i></p>
                <p>Supervisor App</p>
                <p><i class="fal fa-puzzle-piece"></i></p>
                <p>Tablet App</p>
                <p><i class="fal fa-tablet-rugged"></i></p>
                <p>Protective Case</p>
                <p><i class="fal fa-tablet-android"></i></p>
                <p>Samsung Tablet</p>
                <p><i class="fal fa-mobile-android"></i></p>
                <p>Samsung Phone</p>
              </div>
              <router-link
                class="jbtn jbtn-blue"
                :to="{ name: 'BillingCycles', params: { product: 4 } }"
                @click.native="openCycles(4)"
              >
                Choose
              </router-link>
            </div>
          </div>
        </transition>
      </section>
    </div>
    <section class="separator separator-lux jpadding">
      <img src="@/assets/logos/made-in-lux.svg" alt="Made in Luxembourg" />
    </section>
  </div>
</template>

<script>
import firebase from "firebase/app";
import C from "@/constants";
import P from "@/plans";
// import axios from "axios";

export default {
  name: "DashboardSingle",
  data() {
    return {
      activeTab: 0,
      tabs: [true, false],
      subStatusList: P.STATUS_INDEX,
      subTypeList: P.TYPE_INDEX,

      buy: {
        hasUser: false,
        product: P.PRODUCT.BASIC,
        type: P.TYPE.APP,
        plan: null,
      },
      //userId: firebase.auth().currentUser.uid,
      subscriptionsList: [P.DEFAULT_SUB],
    };
  },
  methods: {
    // startContrat(type) {
    //   this.$store.commit("startAddContrat");
    // },
    openCycles(product) {
        var user = firebase.auth().currentUser;
    if (user) {
      // User is signed in.
      this.buy.hasUser = true;
      console.log('plans has user: '+this.buy.hasUser);
    }
      this.buy.product = product;
      this.$store.commit("setBuy", this.buy);
      this.$store.commit("setIsPackage", false);
      this.$store.commit("setMode", C.MODE.BUY);
      console.log("State update: buy.");
      //   this.$store.commit("setBuyStep", 1);
      //   this.$router.push({
      //     name: "BillingCycles",
      //     params: { product: product },
      //   });
    },
    changeTab(clicked) {
      this.$set(this.tabs, this.activeTab, false);
      this.$set(this.tabs, clicked, true);
      this.activeTab = clicked;
    },
    getFormatDate(date) {
      console.log(date);
    },
  },
  mounted() {
    //called with query via plan.type
    if (!this.$route.query.t) {
      console.log("No product selected via query. Default loaded");
    } else {
      if (this.$route.query.t == 2) this.changeTab(0);
      else if (this.$route.query.t == 1) this.changeTab(1);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/plans.scss";
</style>
